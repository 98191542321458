import React, { useState, useEffect } from "react";

// utils
import { formatNumber } from "../../../utils";

// actions
import { getCouponDetails } from "../../../actions/bookPackActions";

const CouponTwinApp = ({
  coupon,
  selectedPlan,
  productId,
  planPrice,
  handleSuccessMsg,
  handleAutoApplyCoupon,
}) => {
  const [serverError, setServerError] = useState("");

  useEffect(() => {
    const handleCouponImplementation = async () => {
      const reqObj = {
        treatmentType: "diabetes",
        couponType: "bookpackCoupon"
      }
      try {
        setServerError("");

        // API CALL -> To get coupon details
        const response = await getCouponDetails(
          coupon.toUpperCase(),
          productId,
          reqObj.couponType,
          selectedPlan,
          reqObj.treatmentType
        );

        if (response.results.length === 0) {
          setServerError("The coupon code is not valid");
        } else if (response.results[0]["status"].toLowerCase() === "expired") {
          setServerError("The coupon code is expired");
        } else {
          const discountedAmount =
            response.results[0]["discountedAmount"] || "";
          const newPlanPrice = parseInt(planPrice) - parseInt(discountedAmount);

          let msg =
            "🎉 Congratulations! you can enroll now with an extra discount of " +
            formatNumber(discountedAmount);

          handleSuccessMsg(msg);
          handleAutoApplyCoupon(newPlanPrice, discountedAmount);
        }
      } catch (error) {
        console.error("coupon code server error", error);
      }
    };

    handleCouponImplementation();
  }, [coupon, selectedPlan]);

  return (
    <>
      {serverError ? (
        <div className="row bgLightBlue">
          <div className="col-12 mt-2 text-center">
            <div className="text-danger">{serverError}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CouponTwinApp;
